import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import {
    SubmissionCheckWrapper,
    HeaderWrapper,
    Note,
    EditableNote,
    NotesWrapper,
    EditButton,
    DotCircleWrapper,
    NoteContent,
    CategoryWrapper,
} from "./index.styles";
import { CustomButton } from "@components/CustomButton";
import { ButtonWrapper } from "@components/TranscriberInput/index.styles";
import { submitFeedback, updateProcessedData } from "@utils/cars.utils";
import { EmptyMessage } from "@components/EmptyMessage";
import EditNotePopup from "./EditNotePopup";

interface SubmissionCheckProps {
    notes: Record<string, any> | null;
    licensePlate: string;
    jobHash: string;
    onProcess?: () => void;
    onDealerPortalClick?: () => void;
    onNotesEdited: (newNotes: Record<string, any>) => void;
}

interface EditingNote {
    category: string;
    title: string;
    index: number;
    isExtraField?: boolean;
}

export const SubmissionCheck: React.FC<SubmissionCheckProps> = ({
    notes,
    licensePlate,
    jobHash,
    onProcess,
    onDealerPortalClick,
    onNotesEdited,
}) => {
    const [editableNotes, setEditableNotes] = useState(notes);
    const [editingNote, setEditingNote] = useState<EditingNote | null>(null);
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setEditableNotes(notes);
    }, [notes]);

    const handleEditClick = (
        category: string,
        title: string,
        index: number,
        isExtraField?: boolean
    ) => {
        setEditingNote({ category, title, index, isExtraField });
    };

    const deleteNote = () => {
        if (editingNote && editableNotes) {
            const { category, title, index, isExtraField } = editingNote;
            const updatedNotes = { ...editableNotes };
            const categoryData = updatedNotes.carData.data[category];

            // Remove it from array
            if (isExtraField) {
                categoryData[title].extra_fields.splice(index, 1);
            } else if (categoryData[title].advices) {
                categoryData[title].advices.splice(index, 1);
            } else {
                delete categoryData[title];
            }

            setEditableNotes(updatedNotes);
            onNotesEdited(updatedNotes);
            updateProcessedData(jobHash, updatedNotes.carData.data);
            setEditingNote(null);
        }
    }

    const handleSaveEdit = (
        newAdvice: string,
        newNote: string,
        newPackage: any
    ) => {
        if (editingNote && editableNotes) {
            const { category, title, index, isExtraField } = editingNote;
            const updatedNotes = { ...editableNotes };
            const categoryData = updatedNotes.carData.data[category];

            if (isExtraField) {
                categoryData[title].extra_fields[index].value = newNote;
            } else if (category === "Algemeen" && title === "Kilometerstand") {
                categoryData[title] = parseInt(newNote);
            } else if (
                category === "Banden" &&
                title === "Bandenregistratie"
            ) {
                categoryData[title][index].advice = newAdvice;
                categoryData[title][index].note = newNote;
            } else if (categoryData[title].advices) {
                const item = categoryData[title].advices[index];
                item.advice = newAdvice;
                item.note = newNote;
                item.packet = newPackage;
            } else {
                categoryData[title] = {
                    advice: newAdvice,
                    note: newNote,
                    packet: newPackage,
                };
            }

            setEditableNotes(updatedNotes);
            onNotesEdited(updatedNotes);
            updateProcessedData(jobHash, updatedNotes.carData.data);
            setEditingNote(null);
        }
    };

    const renderAdvice = (
        advice: any,
        index: number,
        category: string,
        title: string
    ) => (
        <Note key={`${category}-${title}-${index}`}>
            <DotCircleWrapper>
                <GoDotFill
                    color={advice.color || "green"}
                    size={30}
                    style={{ flexShrink: 0 }}
                />
            </DotCircleWrapper>
            <EditableNote>
                <NoteContent>
                    <p style={{ margin: "0px" }}>Advies: {advice.advice}</p>
                    <p style={{ margin: "0px" }}>
                        Notitie: <i>{advice.note}</i>
                    </p>
                    {advice.packet &&
                        Object.keys(advice.packet).length > 0 && (
                            <p style={{ margin: "0px" }}>
                                Pakket: {advice.packet.packet_name}
                            </p>
                        )}
                </NoteContent>
                <EditButton
                    onClick={() => handleEditClick(category, title, index)}
                >
                    Edit
                </EditButton>
            </EditableNote>
        </Note>
    );

    const renderExtraField = (
        field: any,
        index: number,
        category: string,
        title: string
    ) => (
        <Note key={`${category}-${title}-extra-${index}`}>
            <EditableNote>
                <NoteContent>
                    <p style={{ margin: "0px" }}>
                        {field.name}: {field.value}
                    </p>
                </NoteContent>
                <EditButton
                    onClick={() =>
                        handleEditClick(category, title, index, true)
                    }
                >
                    Edit
                </EditButton>
            </EditableNote>
        </Note>
    );

    const renderNote = (category: string, title: string, data: any) => {
        if (category === "Algemeen" && title === "Kilometerstand") {
            return (
                <Note key={`${category}-${title}`}>
                    <NoteContent>Kilometerstand: {data}</NoteContent>
                    <EditButton
                        onClick={() => handleEditClick(category, title, 0)}
                    >
                        Edit
                    </EditButton>
                </Note>
            );
        }

        if (category === "Banden" && title === "Bandenregistratie") {
            return data.map((tire: any, index: number) => (
                <Note key={`${category}-${title}-${index}`}>
                    <DotCircleWrapper>
                        <GoDotFill
                            color={tire.color || "green"}
                            size={30}
                            style={{ flexShrink: 0 }}
                        />
                    </DotCircleWrapper>
                    <EditableNote>
                        <NoteContent>
                            {`${tire.Positie}: ${tire.Merk} ${tire.Seizoen}, ${
                                tire.Breedte
                            }/${tire.Hoogte} R${tire.Maat}, Profieldiepte ${
                                tire.Profieldiepte
                            }mm ${
                                tire["Run on flat"] === "Ja" ? "RunFlat " : ""
                            }Snelheid ${tire.Snelheid} | ${tire.Omschrijving}`}
                        </NoteContent>
                    </EditableNote>
                </Note>
            ));
        }

        return (
            <React.Fragment key={`${category}-${title}`}>
                <h4>{title}</h4>
                {Array.isArray(data.advices) &&
                    data.advices.map((advice: any, index: number) =>
                        renderAdvice(advice, index, category, title)
                    )}
                {Array.isArray(data.extra_fields) &&
                    data.extra_fields.map((field: any, index: number) =>
                        renderExtraField(field, index, category, title)
                    )}
            </React.Fragment>
        );
    };

    const renderCategory = (category: string, categoryData: any) => {
        if (Object.keys(categoryData).length === 0) {
            return <EmptyMessage message="Geen uitvoer om te tonen" />;
        }

        return (
            <CategoryWrapper key={category}>
                <h3>{category}</h3>
                {Object.entries(categoryData).map(([title, data]) =>
                    renderNote(category, title, data)
                )}
            </CategoryWrapper>
        );
    };

    return (
        <SubmissionCheckWrapper>
            <HeaderWrapper>
                <h2>Submission Check</h2>
                <ButtonWrapper>
                    <CustomButton
                        text="Opslaan"
                        iconName="FaSave"
                        onClick={onProcess}
                    />
                    <CustomButton
                        text="Naar eWac"
                        iconName="FaRegWindowRestore"
                        onClick={onDealerPortalClick}
                    />
                </ButtonWrapper>
            </HeaderWrapper>
            <NotesWrapper>
                {editableNotes?.carData.data &&
                    Object.entries(editableNotes.carData.data).map(
                        ([category, categoryData]) =>
                            renderCategory(category, categoryData)
                    )}
            </NotesWrapper>
            {editingNote && editableNotes && (
                <EditNotePopup
                    note={
                        editingNote.isExtraField
                            ? editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].extra_fields[editingNote.index].value
                            : editingNote.category === "Algemeen" &&
                              editingNote.title === "Kilometerstand"
                            ? editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].toString()
                            : editingNote.category === "Banden" &&
                              editingNote.title === "Bandenregistratie"
                            ? editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ][editingNote.index]?.note || ""
                            : editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].advices
                            ? editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].advices[editingNote.index]?.note || ""
                            : editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].note || ""
                    }
                    advice={
                        editingNote.isExtraField
                            ? ""
                            : editingNote.category === "Algemeen" &&
                              editingNote.title === "Kilometerstand"
                            ? ""
                            : editingNote.category === "Banden" &&
                              editingNote.title === "Bandenregistratie"
                            ? editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ][editingNote.index]?.advice || ""
                            : editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].advices
                            ? editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].advices[editingNote.index]?.advice || ""
                            : editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].advice || ""
                    }
                    availablePackages={
                        editableNotes.carData.packages[editingNote.title] || []
                    }
                    package={
                        editingNote.isExtraField
                            ? null
                            : editingNote.category === "Banden" &&
                              editingNote.title === "Bandenregistratie"
                            ? null
                            : editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].advices
                            ? editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].advices[editingNote.index]?.packet || null
                            : editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].packet || null
                    }
                    isKilometerstand={
                        editingNote.category === "Algemeen" &&
                        editingNote.title === "Kilometerstand"
                    }
                    isExtraField={editingNote.isExtraField}
                    extraFieldName={
                        editingNote.isExtraField
                            ? editableNotes.carData.data[editingNote.category][
                                  editingNote.title
                              ].extra_fields[editingNote.index].name
                            : ""
                    }
                    onSave={handleSaveEdit}
                    onClose={() => setEditingNote(null)}
                    onDelete={() => deleteNote()}
                />
            )}
        </SubmissionCheckWrapper>
    );
};

export default SubmissionCheck;
