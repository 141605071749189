import React, { useEffect, useState } from "react";
import { TextArea } from "@components/TextArea";
import { PopupOverlay, PopupContent, PopupButtons } from "./index.styles";
import { CustomButton } from "@components/CustomButton";
import { StyledSelect } from "./EditNotePopup.styles";

interface EditNotePopupProps {
    note: string;
    advice: string;
    package?: any;
    availablePackages: any[];
    isKilometerstand: boolean;
    isExtraField?: boolean;
    extraFieldName?: string;
    onSave: (newAdvice: string, newNote: string, newPackage: any) => void;
    onClose: () => void;
    onDelete: () => void;
}

const EditNotePopup: React.FC<EditNotePopupProps> = ({
    note,
    advice,
    package: currentPackage,
    availablePackages,
    isKilometerstand,
    isExtraField,
    extraFieldName,
    onSave,
    onClose,
    onDelete
}) => {
    const [editedNote, setEditedNote] = useState<string>(note);
    const [editedAdvice, setEditedAdvice] = useState<string>(advice);
    const [selectedPackage, setSelectedPackage] =
        useState<any>(currentPackage);

    const handleSave = () => {
        onSave(editedAdvice, editedNote, selectedPackage);
    };

    const getLabelText = () => {
        if (isKilometerstand) return "Kilometerstand:";
        if (isExtraField) return extraFieldName || "Waarde:";
        return advice === "" ? "Waarde:" : "Notitie:";
    };

    return (
        <PopupOverlay>
            <PopupContent>
                <h3>Notitie Bewerken</h3>
                {!isKilometerstand && !isExtraField && advice !== "" && (
                    <label>
                        Advies:
                        <TextArea
                            value={editedAdvice}
                            onChange={(
                                e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => setEditedAdvice(e.target.value)}
                        />
                    </label>
                )}
                <label>
                    {getLabelText()}
                    <TextArea
                        value={editedNote}
                        onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => setEditedNote(e.target.value)}
                    />
                </label>
                {!isKilometerstand && !isExtraField && (
                    <label>
                        Pakket:
                        <StyledSelect
                            value={selectedPackage?.packet_name || ""}
                            onChange={(e) => {
                                const newPackage = availablePackages.find(
                                    (p) => p.packet_name === e.target.value
                                );
                                setSelectedPackage(newPackage || null);
                            }}
                        >
                            <option value="">Geen pakket</option>
                            {availablePackages.map((pkg, index) => (
                                <option
                                    key={index}
                                    value={pkg.packet_name}
                                >
                                    {pkg.packet_name}
                                </option>
                            ))}
                        </StyledSelect>
                    </label>
                )}
                <PopupButtons>
                    <CustomButton
                        text="Opslaan"
                        iconName=""
                        onClick={handleSave}
                    />
                    <CustomButton
                        text="Annuleren"
                        iconName=""
                        onClick={onClose}
                    />
                    {!isKilometerstand && 
                        <CustomButton
                            backgroundColor="#ff4d4f"
                            iconName="FaTrash"
                            onClick={onDelete}
                        />
                    }
                </PopupButtons>
            </PopupContent>
        </PopupOverlay>
    );
};

export default EditNotePopup;
